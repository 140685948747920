import "./style.scss";
import { formatLongDate } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { IonIcon, IonLabel, IonSpinner } from "@ionic/react";
import { Box, CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import { grey } from "@mui/material/colors";
import { ShiftFilters } from "@src/app/components/shiftFilters";
import { fetchShiftSlotsFromOpenShifts } from "@src/app/components/workWithFriends/utils";
import { isAShiftPath } from "@src/app/routing/constant/tabRoute";
import { isShiftPriorityValid } from "@src/app/utils/shiftHelper";
import { urlQueryString } from "@src/app/utils/urlQueryHelper";
import { useWorkerPreferences } from "@src/appV2/Agents/api/useWorkerPreferences";
import { APP_V2_APP_EVENTS, APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { useGetPaginatedBookabilityStatusForShifts } from "@src/appV2/OpenShifts/ShiftAction/api/useGetBookabilityStatusForShifts";
import { useATeamStatus } from "@src/appV2/Rankings/useATeamStatus";
import { useIsShiftBlocksEnabled } from "@src/appV2/redesign/Shift/Block/useIsShiftBlocksEnabled";
import { ShiftBlockPage } from "@src/appV2/ShiftBlocks/Page";
import { ShiftBookingType, convertToShiftBookingType } from "@src/appV2/ShiftBlocks/utils";
import { ShiftWindow } from "@src/appV2/Shifts/Shift/types";
import { useStreaksEnabledForHcp, useStreaksEnabledGlobally } from "@src/appV2/Streaks";
import { useGetWorkerStreaksProgress } from "@src/appV2/Streaks/api";
import { useHCPStreakData } from "@src/appV2/Streaks/hooks/useHCPStreakData";
import { AgentStreakProgressState } from "@src/appV2/Streaks/types";
import { getPayBoostActiveStreaks } from "@src/appV2/Streaks/utils";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import IconOpenShifts from "@src/assets/images/facilityDetails/open-shifts.svg";
import {
  DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES,
  FACILITY_OPEN_SHIFTS_DATE_RANGE_DAYS,
  SEARCH_MODE,
  USER_EVENTS,
} from "@src/constants";
import { Shift } from "@src/lib/interface";
import { useQuery } from "@tanstack/react-query";
import { addWeeks } from "date-fns";
import { sortBy } from "lodash";
import moment from "moment-timezone";
import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

import { INVISIBLE_SHIFT_FILTER_SEARCH_MODES } from "./constants";
import { FacilityPageStreakBanner } from "./FacilityPageStreakBanner";
import { FacilityShiftItem } from "./facilityShiftItem";
import { HcfProfileSource } from "./model";
import { getEndDateFromParams, getStartDateFromParams } from "./utils";
import { fetchOpenShifts } from "../../openShifts/api";
import { RequestOpenShiftOptions } from "../../openShifts/model";
import { instantPayStatus, isHCPInstantPayAllowed } from "../api";
import { useShiftReqTag } from "../custom-hooks/useShiftReqTag";
import { ShiftPaginationLoader } from "../shiftItem/components/ShiftPagination";

interface FacilityOpenShiftsProps {
  facility: any;
  onClose: () => void;
  hcfProfileSource?: HcfProfileSource;
  searchMode?: SEARCH_MODE;
  onFacilityDetailsClick?: (
    shift: Shift,
    isInstantBook: boolean,
    displayExtraTimePayCard?: boolean
  ) => void;
}

const FacilityOpenShifts: FC<FacilityOpenShiftsProps> = ({
  facility,
  onClose,
  hcfProfileSource,
  searchMode,
  onFacilityDetailsClick,
}) => {
  const location = useLocation();
  const { path: currentRouterUrl } = useRouteMatch();

  const worker = useDefinedWorker();
  const { setFilters } = useWorkerPreferences();
  const { isWorkerAteamEnabled } = useATeamStatus();

  const params = urlQueryString(location.search);

  const startDateTime = getStartDateFromParams(params, searchMode);
  const endDateTime = getEndDateFromParams(params, searchMode);

  const agentReq = params["agentReq"] ?? worker.preference?.qualification;
  const initialShiftBookingType = convertToShiftBookingType(params["shiftBookingType"]);
  const distancePreference = worker.preference?.distance ?? DISTANCE_PREFERENCE_MAX_VALUE_IN_MILES;

  const shiftFiltersAreVisible =
    !searchMode || !INVISIBLE_SHIFT_FILTER_SEARCH_MODES.includes(searchMode);

  const openShiftQueryParams: RequestOpenShiftOptions = {
    dateFilter: {
      start: startDateTime,
      end: endDateTime,
    },
    coordinates: worker.geoLocation?.coordinates ?? [],
    distance: distancePreference,
    qualification: agentReq as string,
    specialities: worker.specialities ?? {},
    facilityId: facility.userId,
  };

  const {
    data: facilityOpenShiftsData,
    isLoading: facilityShiftsIsLoading,
    isSuccess: facilityShiftsIsSuccess,
    refetch: refetchFacilityShifts,
  } = useQuery({
    queryKey: ["facilityOpenShifts", openShiftQueryParams],
    queryFn: async () => {
      const openShiftsResponse = (await fetchOpenShifts(openShiftQueryParams)) ?? [];

      const openShifts = openShiftsResponse?.filter((shift) => !shift.filtered);

      const shiftSlotsFromOpenShifts =
        openShifts.length > 0 ? await fetchShiftSlotsFromOpenShifts(openShifts) : undefined;

      const { openShiftsGroupByDate, openShiftsCount } = transformFacilityOpenShifts({
        openShifts,
        tmz: worker.tmz,
        isWorkerAteamEnabled,
      });

      return {
        facilityShifts: openShiftsGroupByDate,
        facilityShiftsCount: openShiftsCount,
        openShiftSlots: shiftSlotsFromOpenShifts,
      };
    },
    enabled:
      isDefined(facility.userId) &&
      isDefined(startDateTime) &&
      isDefined(endDateTime) &&
      isDefined(distancePreference) &&
      isDefined(agentReq),
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_FACILITY_VIEW_OPEN_SHIFTS_FAILURE,
    },
  });

  const {
    facilityShifts = [],
    facilityShiftsCount = 0,
    openShiftSlots = [],
  } = facilityShiftsIsSuccess ? facilityOpenShiftsData : {};

  const facilityOpenShiftIds = facilityShifts
    .flatMap((facilityShift) => facilityShift.shifts ?? [])
    .filter((shift) => isDefined(shift._id))
    .map((shift) => shift._id ?? "");

  const {
    shiftBookabilityById,
    queryResult: paginatedBookabilityStatusQueryResult,
    bookabilityLoadedShiftsCount: shiftsToRenderCount,
  } = useGetPaginatedBookabilityStatusForShifts({
    shiftIds: facilityOpenShiftIds,
    agentId: worker.userId,
  });

  const {
    isLoading: isLoadingShiftBookability,
    fetchStatus: shiftBookabilityFetchStatus,
    fetchNextPage: fetchNextPageBookabilityStatus,
    isFetchingNextPage: isFetchingShiftBookabilityForNextPage,
    hasNextPage: hasNextOpenShiftPage,
  } = paginatedBookabilityStatusQueryResult;

  // stripe Account Needs Updating Hook condition should automatically close the modal
  useEffect(() => {
    const { pathname } = location;
    const pathnameIsValid = isAShiftPath(pathname);
    if (!pathnameIsValid) {
      onClose();
    }
  }, [location, onClose]);

  useLogEffect(
    USER_EVENTS.VIEWED_FACILITY_SHIFTS,
    {
      ...(initialShiftBookingType === ShiftBookingType.PER_DIEM
        ? { shiftCount: facilityShiftsCount }
        : {}),
      facilityId: facility.userId,
      activeTab: initialShiftBookingType,
    },
    { enabled: facilityShiftsIsSuccess }
  );

  // FIXME: Convert to Tanstack Query
  useEffect(() => {
    fetchHcpInstantPayStatus();
  }, []);

  const [isHcpInstantPayEnabled, setIsHcpInstantPayEnabled] = useState(false);
  const [isLoadingInstantPay, setIsLoadingInstantPay] = useState(true);

  const fetchHcpInstantPayStatus = async () => {
    setIsLoadingInstantPay(true);
    const status = await instantPayStatus();
    setIsHcpInstantPayEnabled(isHCPInstantPayAllowed(status));
    setIsLoadingInstantPay(false);
  };

  const availableShiftIds = useMemo(() => {
    return facilityShifts
      ? facilityShifts
          .map((shiftsDateGroup) => {
            return shiftsDateGroup
              .shifts!.map((shift) => {
                return shift._id;
              })
              .filter((shiftId): shiftId is string => !!shiftId);
          })
          .flat()
      : [];
  }, [facilityShifts]);

  const { allowedAgentReq } = useShiftReqTag();
  const [shiftBookingType, setShiftBookingType] =
    useState<ShiftBookingType>(initialShiftBookingType);
  const isShiftBlocksEnabled = useIsShiftBlocksEnabled();
  const { hcpStreaksResponseData } = useHCPStreakData([facility.userId]);
  const payBoostActiveStreaks = getPayBoostActiveStreaks(hcpStreaksResponseData);
  const { data: streakProgress } = useGetWorkerStreaksProgress({
    agentId: worker.userId,
    filter: {
      date: addWeeks(new Date(), 1),
      facilityIds: [facility.userId],
    },
  });
  const isStreaksEnabledGlobally = useStreaksEnabledGlobally();
  const isStreaksEnabledForHcp = useStreaksEnabledForHcp();
  const isStreaksEnabledAtFacility = streakProgress?.data.some((progress) => {
    return progress.attributes.status !== AgentStreakProgressState.NOT_ENABLED_AT_FACILITY;
  });
  const facilityOpenShiftsAreLoading =
    facilityShiftsIsLoading ||
    (isLoadingShiftBookability && shiftBookabilityFetchStatus !== "idle");

  let shiftIndex = 0;

  return (
    <Fragment>
      <div className="facility-openshifts">
        <Stack spacing={1} sx={{ paddingX: 2 }}>
          <div className="facility-openshifts-title">
            <IonIcon icon={IconOpenShifts} mode="ios" className="hcf-icon" />
            Open Shifts
          </div>
          {isStreaksEnabledGlobally && isStreaksEnabledForHcp && isStreaksEnabledAtFacility && (
            <FacilityPageStreakBanner
              facilityShifts={facilityShifts.flatMap((dateGroup) => dateGroup.shifts)}
              hcpStreaksResponseData={payBoostActiveStreaks}
            />
          )}
          {isShiftBlocksEnabled && (
            <Tabs
              centered
              value={shiftBookingType}
              variant="fullWidth"
              onChange={(_, value) => {
                logEvent(APP_V2_USER_EVENTS.OPEN_SHIFTS_TAB_LAYOUT, {
                  activeTab: value,
                  action: "tab-change",
                  location: currentRouterUrl,
                });
                setShiftBookingType(value);
              }}
            >
              <Tab label="Per Diem" value={ShiftBookingType.PER_DIEM} />
              <Tab label="Blocks" value={ShiftBookingType.BLOCK_BOOKING} />
            </Tabs>
          )}
        </Stack>
        {shiftBookingType === ShiftBookingType.PER_DIEM && (
          <>
            {shiftFiltersAreVisible && (
              <Box sx={{ paddingY: 0.5 }}>
                <ShiftFilters
                  totalShifts={facilityShiftsCount}
                  searchMode={SEARCH_MODE.HCF_PROFILE}
                  distancePreference={distancePreference}
                  qualificationPreference={agentReq}
                  facilityShiftIsLoading={facilityOpenShiftsAreLoading}
                  setFilters={setFilters}
                  agentLicenses={worker.licensesData ?? []}
                />
              </Box>
            )}
            {facilityShiftsIsSuccess && facilityShifts.length === 0 && (
              <Box sx={{ paddingY: 2.5, paddingX: 3 }}>
                <Stack spacing={0.5} alignItems="left" justifyContent="left" flex={1}>
                  <Text bold variant="h3" color={grey[600]}>
                    No per diem shifts at this moment
                  </Text>
                  <Text variant="body2">
                    As soon as this workplace posts a per diem shift for your {agentReq}{" "}
                    qualification, it will be listed here.
                  </Text>
                </Stack>
              </Box>
            )}
            {facilityOpenShiftsAreLoading && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IonSpinner slot="start" name="lines" />
                <IonLabel color="text">
                  <h2>Loading Shifts</h2>
                </IonLabel>
              </div>
            )}
            {facilityShiftsIsSuccess &&
              !isLoadingInstantPay &&
              facilityShifts?.length > 0 &&
              facilityShifts.map((shiftsDateGroup) => {
                return (
                  <div key={shiftsDateGroup.date} className="facility-openshifts-item">
                    {(!isDefined(shiftsToRenderCount) || shiftIndex < shiftsToRenderCount) && (
                      <div className="openshifts-date">{formatLongDate(shiftsDateGroup.date)}</div>
                    )}
                    {shiftsDateGroup.shifts
                      ?.filter((shift) => isDefined(shift._id))
                      .map((shift) => {
                        const shiftBookability =
                          shiftBookabilityById[shift._id ?? ""]?.attributes.bookability;

                        if (isDefined(shiftsToRenderCount) && shiftIndex >= shiftsToRenderCount) {
                          return null;
                        }

                        shiftIndex += 1;

                        return (
                          <Fragment key={shift._id}>
                            {hasNextOpenShiftPage &&
                              isDefined(fetchNextPageBookabilityStatus) &&
                              !isFetchingShiftBookabilityForNextPage &&
                              shiftIndex === shiftsToRenderCount && (
                                <ShiftPaginationLoader
                                  onLoadNextPage={() => {
                                    logEvent(APP_V2_USER_EVENTS.VIEWED_NEXT_OPEN_SHIFT_PAGE, {
                                      searchMode: searchMode ?? SEARCH_MODE.HCF_PROFILE,
                                      shiftsToRenderCount,
                                    });

                                    fetchNextPageBookabilityStatus();
                                  }}
                                  checkElementPosition={false}
                                />
                              )}
                            <FacilityShiftItem
                              getFacilityShifts={() => refetchFacilityShifts()}
                              day={shiftsDateGroup.date}
                              shift={shift}
                              // FIXME: This was ported over from a state variable which was never changed
                              // Verify that this is not used internally and remove the prop
                              shifts={{
                                open: [],
                                assigned: [],
                              }}
                              isHcpInstantPayEnabled={isHcpInstantPayEnabled}
                              searchMode={searchMode ?? SEARCH_MODE.HCF_PROFILE}
                              availableShiftIds={availableShiftIds}
                              filterDistance={distancePreference}
                              filterDays={FACILITY_OPEN_SHIFTS_DATE_RANGE_DAYS}
                              hcfProfileSource={hcfProfileSource}
                              shiftSlots={openShiftSlots}
                              allowedAgentReq={allowedAgentReq}
                              onFacilityDetailsClick={onFacilityDetailsClick}
                              shiftBookability={shiftBookability}
                              isShiftBookabilityLoading={
                                isLoadingShiftBookability || isFetchingShiftBookabilityForNextPage
                              }
                            />
                          </Fragment>
                        );
                      })}
                  </div>
                );
              })}
            {isFetchingShiftBookabilityForNextPage && (
              <Stack
                sx={{
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Stack>
            )}
          </>
        )}
        {shiftBookingType === "block-booking" && (
          <ShiftBlockPage agent={worker} facilityId={facility.userId} />
        )}
      </div>
    </Fragment>
  );
};

const transformFacilityOpenShifts = (parameters: {
  openShifts: Shift[];
  tmz: string;
  isWorkerAteamEnabled: boolean;
}) => {
  const { openShifts, tmz, isWorkerAteamEnabled } = parameters;
  const groupByDate: { [key: string]: { date: string; shifts: Shift[] } }[] = [];
  let openShiftsCount = 0;
  const sortedOpenShifts = sortBy(openShifts, [
    (shift) => (isWorkerAteamEnabled && shift.window === ShiftWindow.A_TEAM ? 0 : 1),
    (shift) => (shift.priorityTill && isShiftPriorityValid(shift.priorityTill) ? 0 : 1),
    "start",
  ]);
  sortedOpenShifts.forEach((shift) => {
    const startDate: string = moment(shift.start).tz(tmz).format("YYYY-MM-DD");
    if (!Object.prototype.hasOwnProperty.call(groupByDate, startDate)) {
      Object.defineProperty(groupByDate, startDate, {
        value: {
          date: startDate,
          shifts: [],
        },
      });
    }
    openShiftsCount++;
    groupByDate[startDate].shifts.push(shift);
  });
  const openShiftsGroupByDate = Object.getOwnPropertyNames(groupByDate)
    .map((date) => groupByDate[date])
    .filter((data) => data);
  return {
    openShiftsGroupByDate,
    openShiftsCount,
  };
};

export { FacilityOpenShifts };
